import Vue from "vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardAction.vue";
// @ts-ignore
import ArrayListItem from "@/components/Content/ArrayListItem.vue";
// @ts-ignore
import { isEmpty, isUndefined } from "lodash";
//@ts-ignore
import { getError } from '@/utils/resolveObjectArray';
// @ts-ignore
import { prepareReportParams } from "@/utils/reportData";
import { mapActions, mapGetters } from "vuex";
import {
	isRequired,
	isAfterCompare,
	isMaxDays,
    isEmail
	//@ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { CardActionEmit, CardActionType } from "@/interfaces/action";
import { Status } from "@/interfaces/state";
//@ts-ignore
import { getPermisionReport } from "@/utils/permissionResolve";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default Vue.extend({
    name:"Scheduled",
    props:{
        report: {
			type: Object,
		},
		resources: {
			type: Object,
		},
        reportData: {
			type: Object,
            default: function () {
				return {};
			},
		},
        edit: {
			type: Boolean,
            default: false
		},
    },
    components:{
        CardAutocomplete,
        CardTextField,
        CardActions,
        DatePicker,
        DataPickerStarTime,
        ArrayListItem
    },
    data: () => ({
        valid: true,
        accountMaster: false,
        startDateScheduler: new Date(),
        scheduler:{
            schedulerType: "",
            formatData: "",
            schedule_start_date: "",
            schedule_end_date:"",
            email_subject:"",
            emails: [],
            email_to: "",
            includeEmail: false
        },
        config:{
            showMsg: false,
            Msg: "",
        },
        rules:{
            start_date_rule: [],
            end_date_rule: [],
            subject_rule: []
        },
        showActionButtons: [] as string[],
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            await this.fetchSchedulerTypes('scheduler_type');
            await this.loadData();
            await this.setValuesDefault();
            if(this.edit){
                await this.setDataReport();
            }
            await this.getBtnShows();
        });
    },
    computed:{

        ...mapGetters("profile", ["getAbility"]),

        getErrors() {
			return this.$store.state.proccess.errors
		},

        getRules() {
			return {
				isRequired,
				isAfterCompare,
				isMaxDays,
                isEmail
			};
		},

        getRuleEmail(){
            if(isEmpty(this.scheduler.email_to)){
                return [];
            }
            return [this.getRules.isEmail(this.scheduler.email_to)]
        },

        getMinDate() {
			return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
		},
        getMinDateEdit() {
            if(!(this.reportData.status === Status.PENDING)){
	            return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
            }else{
                return this.moment(this.scheduler.schedule_start_date).format(
                    DEFAULT_DATE_TIME_FORMAT
                );
            }
		},

        getMinEndDate() {
			return this.moment(this.scheduler.schedule_start_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
		},

        getStartDateScheduler(){
            return this.moment(this.scheduler.schedule_start_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
        },

        getEndDateScheduler(){
            return this.moment(this.scheduler.schedule_end_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
        },

        isAccountMaster(){
            return this.accountMaster;
        },

        isEdit(){
            return this.edit;
        },

        isPending(){
            return this.verifyStatus(Status.PENDING);
        },

        isFail(){
            return this.verifyStatus(Status.FAIL);
        },

        disabledData() {
			return {
				[CardActionType.SCHEDULER_UPDATE]: this.isFail,
				// [CardActionType.CANCEL]: !this.isEdit,
			};
		},
        
        canCreate(){
            return this.getAbility.can(this.getPermission.actions.create_scheduler, this.getPermission.subject);
        },
    
        getPermission(){
            return getPermisionReport();
        }
    },
    methods:{

        ...mapActions("report", ["createSchedulerReport","editSchedulerReport"]),
        ...mapActions("loading", ["setLoadingData"]),
        ...mapActions("account", ["getAccount"]),

        verifyStatus(status: Status){
            return this.reportData.status === status;
        },

        async getBtnShows() {
			
			let buttons: string[] = [];

			this.showActionButtons = buttons;

            if(this.isEdit) {
                buttons.push(CardActionType.SCHEDULER_UPDATE);				
			} else {
                buttons.push(CardActionType.SCHEDULER_CREATE);
            };

            buttons.push(CardActionType.CANCEL);

			this.showActionButtons = buttons;

			return buttons;
		},

        getError(index: any) {
			return getError(this.getErrors, index)
		},

        async loadData(){
            const response = await this.getAccount();
            this.accountMaster = response.master_account;
        },

        async findEmail(email: string){
            return this.scheduler.emails.find(m => m == email);
        },

        async setValuesDefault(){
            setTimeout(() =>{
                this.scheduler.formatData = this.report.type;
                if(!isEmpty(this.resources.scheduler_type)){
                    if(this.edit){
                        const scheduletType = this.resources.scheduler_type.find(({value}) => value == this.reportData.scheduler_type);
                        this.scheduler.schedulerType = scheduletType.id;
                        this.scheduler.formatData = this.reportData?.file_type;
                    }else{
                        this.scheduler.schedulerType = this.resources.scheduler_type[0].id;
                    }
                };
            }, 1000);
        },

        async validate() {
			return await this.$refs.form.validate();
		},

        async handleAction(action: { type: any }) {
			try {
				switch (action.type) {
					case CardActionEmit.SCHEDULER_CREATE:
                        await this.addRules();
                        await this.CreateScheduler();
						break;
					case CardActionEmit.SCHEDULER_UPDATE:
                        await this.addRules();
                        await this.EditScheduler();
                        break;
					case CardActionEmit.CANCEL:
                        await this.cancerUpdate();
                        break;
				}
			} catch (error) {}
		},

        async addRules(){
            this.rules.subject_rule = [this.getRules.isRequired];
            this.rules.start_date_rule = [this.getRules.isRequired];
            this.rules.end_date_rule = [this.getRules.isRequired,
                this.getRules.isAfterCompare(
                    this.scheduler.schedule_end_date,
                    this.scheduler.schedule_start_date
                ),
                this.getRules.isMaxDays(
                    this.scheduler.schedule_end_date,
                    this.scheduler.schedule_start_date,
                    90
                )
        ];
        },

        async clearRules(){
            this.rules.subject_rule = [];
            this.rules.start_date_rule = [];
            this.rules.end_date_rule = [];
        },

        async CreateScheduler(){
            try {
				if (!(await this.validate())) return;
                if(isEmpty(this.scheduler.emails)){
                    this.config.showMsg = true;
                    this.config.Msg = this.$t("report.scheduler.msgToEmail");
                    return;
                }
                this.config.Msg = "";
                this.config.showMsg = false;
				await this.setLoadingData(TypeLoading.loading);
                const dataSend = await this.GeneratedData();
                await this.createSchedulerReport(dataSend);
				await this.setLoadingData();
                this.$emit('update-list');
                await this.clearData();
			} catch (error) {
				await this.setLoadingData();
			}
        },

		async EditScheduler(){
            try {
				if (!(await this.validate())) return;
                if(isEmpty(this.scheduler.emails)){
                    this.config.showMsg = true;
                    this.config.Msg = this.$t("report.scheduler.msgToEmail");
                    return;
                }
                this.config.Msg = "";
                this.config.showMsg = false;
				await this.setLoadingData(TypeLoading.loading);
                const dataSend = await this.GeneratedData();
                await this.editSchedulerReport({data: dataSend, id: this.reportData.id});
				await this.setLoadingData();
                await this.clearData();
                this.hideFormScheuler();
			} catch (error) {
				await this.setLoadingData();
			}
        },

        async GeneratedData(){
            const payload = await prepareReportParams(this.report);
            const dataSend = {
                scheduler_type: this.scheduler.schedulerType,
                email_subject: this.scheduler.email_subject,
                emails: this.scheduler.emails,
                schedule_start_date: this.getStartDateScheduler,
                schedule_end_date: this.getEndDateScheduler,
                include_account_email: this.scheduler.includeEmail ? 1 : 0,
                file_type: this.scheduler.formatData
            }

            const obj = {...payload, ...dataSend}

            return obj;
        },

        async clearData(){
            await this.clearRules();
            this.scheduler.schedulerType = "";
            this.scheduler.formatData = "";
            this.scheduler.schedule_start_date = "";
            this.scheduler.schedule_end_date = "";
            this.scheduler.email_subject = "";
            this.scheduler.emails = [];
            this.scheduler.email_to = "";
            this.scheduler.includeEmail = false;
            await this.setValuesDefault();
        },

        async handleDelete(event: String){
            if(!isEmpty(event)){
                let idx = -1;
                this.scheduler.emails.forEach((element, index) => {
                    if(element == event) idx = index;
                });
                if(idx != -1){
                    this.scheduler.emails.splice(idx, 1);
                }
            }
        },

        async handleAppend(event: any){
            if (event instanceof KeyboardEvent) {
				event.preventDefault();
				event.stopPropagation();
				if (event.keyCode == 13) {
                    if(!isEmpty(this.scheduler.email_to)){
                        if(this.getRules.isEmail(this.scheduler.email_to) == true){
                            if(isUndefined(await this.findEmail(this.scheduler.email_to))){
                                this.scheduler.emails.push(this.scheduler.email_to);
                                this.scheduler.email_to = "";
                            }
                        }
                    }
				}
			}
        },

        async fetchSchedulerTypes(field_type: string){
            this.$emit("fetch-scheduler-type", field_type);
        },

        async setDataReport(){
            const emails = JSON.parse(this.reportData.emails);
            this.scheduler.formatData = this.reportData?.file_type;
            this.scheduler.schedule_start_date = this.reportData.start_date;
            this.scheduler.schedule_end_date = this.reportData.end_date;
            this.scheduler.email_subject = this.reportData.email_subject;
            this.scheduler.emails = emails;

            if(!isEmpty(this.resources.scheduler_type)){
                const scheduletType = this.resources.scheduler_type.find(({value}) => value == this.reportData.scheduler_type);
                this.scheduler.schedulerType = scheduletType.id;
            }
        },

        async cancerUpdate(){
            await this.clearData();
            this.hideFormScheuler();
        },

        hideFormScheuler(){
            this.$emit("hide-form-scheduler");
        }
    },
    watch:{
        async "resources.scheduler_type"(){
            if(this.edit){
                const scheduletType = this.resources.scheduler_type.find(({value}) => value == this.reportData.scheduler_type);
                this.scheduler.schedulerType = scheduletType.id;
            }
        },
        async "scheduler.schedule_start_date"(val){
            if(val){
                this.rules.start_date_rule = [this.getRules.isRequired];
            }else{
                this.rules.start_date_rule = [];
            }
        },
        async "scheduler.schedule_end_date"(val){
            if(val){
                this.rules.end_date_rule = [this.getRules.isRequired,
                    this.getRules.isAfterCompare(
                        this.scheduler.schedule_end_date,
                        this.scheduler.schedule_start_date
                    ),
                    this.getRules.isMaxDays(
                        this.scheduler.schedule_end_date,
                        this.scheduler.schedule_start_date,
                        90
                )];
            }else{
                this.rules.end_date_rule = [];
            }
        },
    }
})
